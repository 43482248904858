<template>
    <v-container>
        <page-filters-section top-img="/img/leaderboard/leaderboard-header.png">
            <h1>
                Leaderboard
                <span class="hidden-sm-and-down">-</span>
                <br class="hidden-md-and-up"/>
                Season 3
            </h1>
            <div class="text-center">
                <h3 class="hidden-sm-and-down">Season 3 has started!</h3>
                <h4>Nov 15 - Feb 15</h4>
            </div>
        </page-filters-section>

        <div class="page-content leaderboard-content text-center">
            <img src="/img/leaderboard/green-l.png" class="corner-wizard-img"/>
            <img src="/img/leaderboard/blue-b.png" class="bottom-wizard-img"/>

            <v-progress-circular
                v-if="null === leaderboardItems"
                class="ma-auto mt-15"
                indeterminate
                size="120"
            ></v-progress-circular>

            <div v-if="leaderboardItems && 0 === leaderboardItems.length" class="mt-15 pt-15">
                <h3>No participants in leaderboard yet</h3>
            </div>

            <div v-if="leaderboardItems && leaderboardItems.length>0">
                <div class="l-table">
                    <div class="l-row l-row-header">
                        <div class="l-cell l-cell-position l-header l-header-position">
                            <span class="hidden-sm-and-down">Position</span>
                            <span class="hidden-md-and-up">Pos.</span>
                        </div>
                        <div class="l-cell l-cell-reward l-header l-header-reward">Reward</div>
                        <div class="l-cell l-cell-username l-header l-header-username">Username</div>
                        <div v-if="'LEVEL' === leaderboardType" class="l-cell l-cell-level l-header l-header-level">
                            Level
                        </div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-if="'RANKING_POINTS' === leaderboardType"
                                     v-bind="attrs"
                                     v-on="on"
                                     class="l-cell l-cell-level l-header l-header-level">
                                    <span class="hidden-sm-and-down">Ranking Points</span>
                                    <span class="hidden-md-and-up">RP</span>

                                    <v-icon small class="hidden-sm-and-down ml-1">mdi-help-circle-outline</v-icon>
                                    <v-icon x-small class="hidden-md-and-up ml-1">mdi-help-circle-outline</v-icon>
                                </div>
                            </template>
                            <span>Difference between won and lost matches during current Season</span>
                        </v-tooltip>
                    </div>
                    <div :class="`l-section-${i}`" v-for="(row, i) in leaderboardItems">
                        <div class="l-row" :class="isAccountInRow(row) ? 'l-selected' : ''">
                            <div class="l-cell l-cell-position" :class="`position-${row.position}`">
                                {{ row.position }}
                            </div>
                            <div class="l-cell l-cell-reward">
                                <leaderboard-reward :reward="row.rewards[0]"/>
                            </div>
                            <div class="l-cell l-cell-img">
                                <img class="avatar-img"
                                     :src="`/img/profile/avatars/${row.account.avatar}.jpg`"
                                />
                            </div>
                            <div class="l-cell l-cell-username">
                                {{ row.account.username }}
                            </div>
                            <div v-if="'LEVEL' === leaderboardType" class="l-cell l-cell-level" v-title
                                 :title="expTitle(row.account.level, row.account.experience)">
                                {{ row.account.level }} lvl
                            </div>
                            <div v-if="'RANKING_POINTS' === leaderboardType" class="l-cell l-cell-level">
                                {{ row.account.rankingPoints }} RP
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!accountInLeaderboard && userAccount.username && seasonDuringProgress"
                     class="l-table l-table-current-player">
                    <div class="l-row l-row-header">
                        <div class="l-cell l-cell-position l-header l-header-position">...</div>
                        <div class="l-cell l-cell-rewardl-cell-reward l-header l-header-reward">...</div>
                        <div class="l-cell l-cell-img"></div>
                        <div class="l-cell l-cell-username">...</div>
                        <div class="l-cell l-cell-level l-header l-header-level">...</div>
                    </div>
                    <div :class="`l-section-X`">
                        <div class="l-row l-selected">
                            <div class="l-cell l-cell-position" :class="`position-X}`">
                                -
                            </div>
                            <div class="l-cell l-cell-reward">
                                -
                            </div>
                            <div class="l-cell l-cell-img">
                                <img class="avatar-img"
                                     :src="`/img/profile/avatars/${userAccount.avatar}.jpg`"
                                />
                            </div>
                            <div class="l-cell l-cell-level">
                                {{ userAccount.username }}
                            </div>
                            <div v-if="'LEVEL' === leaderboardType" class="l-cell l-cell-level" v-title
                                 :title="expTitle(row.account.level, row.account.experience)">
                                {{ userAccount.level }} lvl
                            </div>
                            <div v-if="'RANKING_POINTS' === leaderboardType" class="l-cell l-cell-level">
                                {{ userAccount.rankingPoints }} RP
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>


<script>
import PageFiltersSection from "../components/page-filters-section";
import {apiServiceVue} from "@/services/apiService.vue";
import LeaderboardReward from "@/components/leaderboard/leaderboard-reward";

export default {
    components: {LeaderboardReward, PageFiltersSection},
    props: [],
    data() {
        return {
            seasonDuringProgress: false,
            leaderboardType: null,
            currentPlayerPosition: null,
            leaderboardItems: null,
            accountInLeaderboard: true,
        }
    },
    mounted() {
        this.fetchLeaderboard();
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        userAccount() {
            return this.$store.state.userAccount;
        },
    },
    methods: {
        isAccountInRow(row) {
            const accountInRow = this.userAccount.username === row.account.username;
            if (accountInRow) {
                this.accountInLeaderboard = true;
            }

            return accountInRow;
        },
        expTitle(curLevel, expAmount) {
            return curLevel + ' lv | ' + this.$options.filters.localFormatNumber(expAmount) + ' exp';
        },
        fetchLeaderboard() {
            this.accountInLeaderboard = false;
            this.leaderboardItems = null;

            const url = process.env.VUE_APP_APP_URL + 'leaderboard/season/current';
            apiServiceVue.get(url)
                .then(response => {
                    this.seasonDuringProgress = !response.data?.seasonEnded || false;
                    this.leaderboardType = response.data?.type || 'LEVEL';
                    this.leaderboardItems = response.data?.positions || [];
                })
                .catch(e => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Error occurred'
                    });
                });
        }
    }
};
</script>

<style lang="scss" scoped>
$purple-bg: #4c356c;
$purple-light: #7654a9;
$position-1-bg: #ffc602;
$position-2-bg: #7c9898;
$position-3-bg: #995f0f;

.leaderboard-content {
    margin-bottom: 70px;
}

.corner-wizard-img {
    position: absolute;
    top: 40%;
    left: 0;
}

.bottom-wizard-img {
    height: 150px;
    position: absolute;
    bottom: 0;
    right: 5%;
}

.l-table {
    width: 100%;
    max-width: 1100px;
    background: $purple-bg;
    margin: auto;

    .l-row-header {
        &.l-row {
            &:hover {
                box-shadow: none;
            }
        }
    }

    .l-section-0 {
        background: $position-1-bg;
        text-shadow: 0 0 4px #444;

        .l-row {
            &:hover {
                box-shadow: inset 0 0 20px #333;
            }
        }
    }

    .l-section-1 {
        background: $position-2-bg;

        .l-row {
            &:hover {
                box-shadow: inset 0 0 20px #333;
            }
        }
    }

    .l-section-2 {
        background: $position-3-bg;
    }

    .l-row {
        width: 100%;
        height: 60px;
        margin: 0;
        padding: 0;
        border-top: 2px solid #38294a;
        border-bottom: 2px solid #38294a;

        &.l-row-header {
            height: 40px;
        }

        &:hover {
            box-shadow: inset 0 0 20px #999;
        }

        &.l-selected {
            width: 104%;
            height: 70px;
            margin: 0 0 0 -2%;
            background: $purple-light;
        }
    }

    .l-cell {
        width: auto;
        height: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
        line-height: 1;
        display: flex;
        float: left;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        border-left: 4px solid #38294a;

        &.l-cell-reward {
            width: 20%;
        }

        &.l-cell-position {
            width: 15%;
        }

        $img-cell-width: 10%;

        &.l-cell-img {
            width: $img-cell-width;
            justify-content: normal;

            .avatar-img {
                height: 90%;
                margin: 0 0 0 10%;
                padding: 0;
                display: block;
                content: '';
            }
        }

        $username-width: 40%;

        &.l-cell-username {
            width: $username-width;
            border-left: none;
            overflow: hidden;
        }

        &.l-header-username {
            width: calc(#{$username-width} + #{$img-cell-width});
            border-left: 1px solid #38294a;
        }

        &.l-cell-level {
            width: 15%;
        }
    }
}

.position-1,
.position-2,
.position-3 {
    color: transparent;
    background: url('/img/leaderboard/place-1.png') center center no-repeat;
    background-size: 40px;

    &.position-2 {
        background-image: url('/img/leaderboard/place-2.png');
    }

    &.position-3 {
        background-image: url('/img/leaderboard/place-3.png');
    }
}


@media only screen and (max-width: $md) {
    .container {
        padding-left: 0px;
        padding-right: 0px;
    }
    .corner-wizard-img {
        display: none;
    }
    .v-application h1 {
        line-height: 100%;
        margin-bottom: 20px
    }
    .l-table .l-cell {
        &.l-cell-position {
            width: 10%;
        }

        &.l-cell-reward {
            width: 30%;
        }

        &.l-cell-username {
            width: 35%;
        }

        &.l-header-username {
            width: 45%;
        }
    }
}

@media only screen and (max-width: $xs) {
    .bottom-wizard-img {
        height: 100px;
        right: 2%;
    }
    .position-1,
    .position-2,
    .position-3 {
        background-size: 30px;
    }
    .l-table {
        width: 100%;
        font-size: 14px;

        .l-row {
            width: 100%;
            height: 50px;

            &.l-selected {
                width: 104%;
                height: 60px;
            }
        }
    }
}

@media only screen and (max-width: $xxs) {
    .position-1,
    .position-2,
    .position-3 {
        background-size: 28px;
    }
    .l-table {
        width: 100%;
        font-size: 14px;

        .l-row {
            width: 100%;
            height: 50px;

            &.l-selected {
                width: 104%;
                height: 60px;
            }

            .l-cell {
                &.l-cell-img {
                    .avatar-img {
                        height: 80%;
                        margin: 0 0 0 0;
                    }
                }
            }
        }
    }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "page-filters-section",
        { attrs: { "top-img": "/img/leaderboard/leaderboard-header.png" } },
        [
          _c("h1", [
            _vm._v(" Leaderboard "),
            _c("span", { staticClass: "hidden-sm-and-down" }, [_vm._v("-")]),
            _c("br", { staticClass: "hidden-md-and-up" }),
            _vm._v(" Season 3 "),
          ]),
          _c("div", { staticClass: "text-center" }, [
            _c("h3", { staticClass: "hidden-sm-and-down" }, [
              _vm._v("Season 3 has started!"),
            ]),
            _c("h4", [_vm._v("Nov 15 - Feb 15")]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "page-content leaderboard-content text-center" },
        [
          _c("img", {
            staticClass: "corner-wizard-img",
            attrs: { src: "/img/leaderboard/green-l.png" },
          }),
          _c("img", {
            staticClass: "bottom-wizard-img",
            attrs: { src: "/img/leaderboard/blue-b.png" },
          }),
          null === _vm.leaderboardItems
            ? _c("v-progress-circular", {
                staticClass: "ma-auto mt-15",
                attrs: { indeterminate: "", size: "120" },
              })
            : _vm._e(),
          _vm.leaderboardItems && 0 === _vm.leaderboardItems.length
            ? _c("div", { staticClass: "mt-15 pt-15" }, [
                _c("h3", [_vm._v("No participants in leaderboard yet")]),
              ])
            : _vm._e(),
          _vm.leaderboardItems && _vm.leaderboardItems.length > 0
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "l-table" },
                  [
                    _c(
                      "div",
                      { staticClass: "l-row l-row-header" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "l-cell l-cell-position l-header l-header-position",
                          },
                          [
                            _c("span", { staticClass: "hidden-sm-and-down" }, [
                              _vm._v("Position"),
                            ]),
                            _c("span", { staticClass: "hidden-md-and-up" }, [
                              _vm._v("Pos."),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "l-cell l-cell-reward l-header l-header-reward",
                          },
                          [_vm._v("Reward")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "l-cell l-cell-username l-header l-header-username",
                          },
                          [_vm._v("Username")]
                        ),
                        "LEVEL" === _vm.leaderboardType
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "l-cell l-cell-level l-header l-header-level",
                              },
                              [_vm._v(" Level ")]
                            )
                          : _vm._e(),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      "RANKING_POINTS" === _vm.leaderboardType
                                        ? _c(
                                            "div",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "l-cell l-cell-level l-header l-header-level",
                                                },
                                                "div",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "hidden-sm-and-down",
                                                },
                                                [_vm._v("Ranking Points")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "hidden-md-and-up",
                                                },
                                                [_vm._v("RP")]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "hidden-sm-and-down ml-1",
                                                  attrs: { small: "" },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-help-circle-outline"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "hidden-md-and-up ml-1",
                                                  attrs: { "x-small": "" },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-help-circle-outline"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2339749977
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Difference between won and lost matches during current Season"
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.leaderboardItems, function (row, i) {
                      return _c("div", { class: `l-section-${i}` }, [
                        _c(
                          "div",
                          {
                            staticClass: "l-row",
                            class: _vm.isAccountInRow(row) ? "l-selected" : "",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "l-cell l-cell-position",
                                class: `position-${row.position}`,
                              },
                              [_vm._v(" " + _vm._s(row.position) + " ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "l-cell l-cell-reward" },
                              [
                                _c("leaderboard-reward", {
                                  attrs: { reward: row.rewards[0] },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "l-cell l-cell-img" }, [
                              _c("img", {
                                staticClass: "avatar-img",
                                attrs: {
                                  src: `/img/profile/avatars/${row.account.avatar}.jpg`,
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              { staticClass: "l-cell l-cell-username" },
                              [_vm._v(" " + _vm._s(row.account.username) + " ")]
                            ),
                            "LEVEL" === _vm.leaderboardType
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "title", rawName: "v-title" },
                                    ],
                                    staticClass: "l-cell l-cell-level",
                                    attrs: {
                                      title: _vm.expTitle(
                                        row.account.level,
                                        row.account.experience
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(row.account.level) + " lvl "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            "RANKING_POINTS" === _vm.leaderboardType
                              ? _c(
                                  "div",
                                  { staticClass: "l-cell l-cell-level" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.account.rankingPoints) +
                                        " RP "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ])
                    }),
                  ],
                  2
                ),
                !_vm.accountInLeaderboard &&
                _vm.userAccount.username &&
                _vm.seasonDuringProgress
                  ? _c(
                      "div",
                      { staticClass: "l-table l-table-current-player" },
                      [
                        _c("div", { staticClass: "l-row l-row-header" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "l-cell l-cell-position l-header l-header-position",
                            },
                            [_vm._v("...")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "l-cell l-cell-rewardl-cell-reward l-header l-header-reward",
                            },
                            [_vm._v("...")]
                          ),
                          _c("div", { staticClass: "l-cell l-cell-img" }),
                          _c("div", { staticClass: "l-cell l-cell-username" }, [
                            _vm._v("..."),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "l-cell l-cell-level l-header l-header-level",
                            },
                            [_vm._v("...")]
                          ),
                        ]),
                        _c("div", { class: `l-section-X` }, [
                          _c("div", { staticClass: "l-row l-selected" }, [
                            _c(
                              "div",
                              {
                                staticClass: "l-cell l-cell-position",
                                class: `position-X}`,
                              },
                              [_vm._v(" - ")]
                            ),
                            _c("div", { staticClass: "l-cell l-cell-reward" }, [
                              _vm._v(" - "),
                            ]),
                            _c("div", { staticClass: "l-cell l-cell-img" }, [
                              _c("img", {
                                staticClass: "avatar-img",
                                attrs: {
                                  src: `/img/profile/avatars/${_vm.userAccount.avatar}.jpg`,
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "l-cell l-cell-level" }, [
                              _vm._v(
                                " " + _vm._s(_vm.userAccount.username) + " "
                              ),
                            ]),
                            "LEVEL" === _vm.leaderboardType
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "title", rawName: "v-title" },
                                    ],
                                    staticClass: "l-cell l-cell-level",
                                    attrs: {
                                      title: _vm.expTitle(
                                        _vm.row.account.level,
                                        _vm.row.account.experience
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.userAccount.level) +
                                        " lvl "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            "RANKING_POINTS" === _vm.leaderboardType
                              ? _c(
                                  "div",
                                  { staticClass: "l-cell l-cell-level" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.userAccount.rankingPoints) +
                                        " RP "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }